<script>
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"
	import Multiselect from "vue-multiselect"
	import DatePicker from "vue2-datepicker"

	export default {
		middleware: "authentication",
		page: {
			title: "",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
			DatePicker,
		},
		data: function() {
			return {
				items: [
					{
						text: "View",
					},
					{
						text: "Attendance",
						active: true,
					},
				],
				isReportTableBusy: false,
				excelDownloading: false,
				totalRows: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				tableData: [],
				moment: this.$moment,
				employeeFilterBranch: [],
				selectedBranch: null,
				sortBy: "updatedAt",
				sortDesc: true,
				filter: '',
				corporateId: '',
				reimbursementStatus: '',
				fields: [
					{
						key: 'actions',
						label: 'Actions',
						tdClass: 'align-center align-middle',
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
					{
						key: 'expenseId',
						label: 'Expense ID',
						tdClass: 'align-center align-middle',
						sortable: true,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
					{
						key: 'category',
						label: 'Category',
						tdClass: 'align-center align-middle',
						sortable: true,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
					{
						key: 'merchantName',
						label: 'Merchant Name',
						tdClass: 'align-center align-middle',
						sortable: true,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
					{
						key: 'date',
						label: 'Date',
						tdClass: 'align-center align-middle',
						sortable: true,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
					{
						key: 'amount',
						label: 'Amount',
						tdClass: 'align-center align-middle',
						sortable: true,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
					{
						key: 'reason',
						label: 'Reason',
						tdClass: 'align-center align-middle',
						sortable: true,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
					{
						key: 'supportingDocument',
						label: 'Supporting Document',
						tdClass: 'align-center align-middle',
						sortable: true,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
					{
						key: 'status',
						label: 'Status',
						tdClass: 'align-center align-middle',
						sortable: true,
						thStyle: { backgroundColor: '#f6f6f6' },
					},
				],
				daterange: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
				reimbursementFilterOptions: [
					'Pending',
					'Approved',
					'Denied',
				],
			}
		},
		computed: {
			rows: function() {
				return this.tableData.length
			},
		},
		mounted: function() {
			this.corporateId = localStorage.getItem('corporateId')
			this.getBranchList()
		},
		methods: {
			getBranchList: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then(response => {
					this.employeeFilterBranch = response.data.data
					this.selectedBranch = this.employeeFilterBranch[0]
					this.getReimbursementList()
				}).catch(error => {
					console.log(error)
				})
			},
			getColor(status) {
				if (status === 'Approved') return "badge badge-pill bg-soft-success font-size-13 bg-soft-success"
				if (status === 'Pending') return "badge badge-pill bg-soft-success font-size-13 bg-soft-warning"
				return "badge badge-pill bg-soft-success font-size-13 bg-soft-danger";
			},
			getReimbursementList: function() {
				this.axios.post('employee-expense/list', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
					branchId: this.selectedBranch._id,
					page: this.currentPage,
					pageSize: this.perPage,
					approvalStatus: this.reimbursementStatus,
					searchKeyword: '',
					startDate: this.daterange[0],
					endDate: this.daterange[1],
				}).then(response => {
					console.log(response.data)
				}).catch(error => {
					console.log(error)
				})
			},
			changePageSize: function(value) {
				this.perPage = value
				this.getReimbursementList()
			},
			changePage: function(value) {
				this.currentPage = value
				this.getReimbursementList()
			},
			applyFilter: function() {},
			clearFilter: function() {},
		},
	}
</script>
<style></style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card mb-4 filter-card">
					<div class="card-body">
						<div class="row inner">
							<div class="col-md-2" style="width: 20%">
								<label>Date Range </label>
								<date-picker v-model="daterange" format="DD MMM Y" append-to-body range lang="en"></date-picker>
							</div>
							<div class="col-md-2" style="width: 18%;">
								<label>Select </label>
								<multiselect v-model="reimbursementStatus" :options="reimbursementFilterOptions" :show-labels="false" />
							</div>
							<div class="col-md-4">
								<label for="branch">Select Branch</label>
								<multiselect id="branch" v-model="selectedBranch" :options="employeeFilterBranch" placeholder="Select Branch" :multiple="false" track-by="name" label="name" :allow-empty="false">
									<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								</multiselect>
							</div>
							<div class="col-md-2" style="width: auto;">
								<button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">Apply Filter</button>
							</div>
							<div class="col-md-2" style="width: auto;">
								<button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">Clear Filter</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="row mb-4">
							<div class="col-sm-12 col-md-4">
								<!-- <div class="btn-group" role="group">
									<button class="btn btn-outline-primary" type="button">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
								</div> -->
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">
										<span>Show</span>
										<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage" size="sm" :options="pageOptions" @change="changePageSize"></b-form-select>
										<span>entries</span>
									</label>
								</div>
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label class="d-inline-flex align-items-center">
										<span>Search:</span>
										<b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2" @keyup="searchFilter(filter)"></b-form-input>
									</label>
								</div>
							</div>
						</div>
						<div class="table-responsive mb-0">
							<b-table striped hover outlined bordered :items="tableData" :fields="fields"
								thead-class="bg-transparent" responsive="sm" :per-page="0"
								:current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								fixed-header :busy="isReportTableBusy" show-empty>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<b-pagination
											v-model="currentPage"
											:total-rows="rows"
											:per-page="perPage"
											@change="changePage" />
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>